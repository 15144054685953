up.compiler('[prefill-amount-addon]', (element) => {

  element.addEventListener('click', copyAmount)

  function copyAmount() {
    let inputToCopyFrom = element.closest('td').previousElementSibling.querySelector('input')
    let inputToCopyTo = element.closest('.input-group').querySelector('input')

    let amount = inputToCopyFrom.value

    if (!inputToCopyTo.disabled) {
      inputToCopyTo.value = amount
      up.emit(inputToCopyTo, 'change')
    }
  }

  return () => {
    element.removeEventListener('click', copyAmount)
  }

})
