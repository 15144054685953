import {showRecordLock, hideRecordLock} from '../modules/hide_record_locks'
import customModal from '../modules/custom_modal'
import fetchWithCSRFToken from "../modules/fetch";

class RecordLockRefresher {
  constructor(element, options) {
    this.element = element
    this.refreshUri = options.refresh_uri
    this.refreshInterval = options.refresh_interval
    this.abortUri = options.abort_uri
    this.releaseUri = options.release_uri
    this.lockId = options.lock_id
    this.submitted = false
    this.failCount = 0
  }

  startRefreshing() {
    this.interval = setInterval(() => { this.refreshLock() }, this.refreshInterval)
    hideRecordLock(this.lockId)
  }

  stopRefreshing() {
    if ( this.interval ) {
      clearInterval(this.interval)
      this.interval = undefined
      showRecordLock(this.lockId)
    }
  }

  release() {
    fetchWithCSRFToken(this.releaseUri, { method: 'delete' })
  }

  // private

  refreshLock() {
    if ( document.documentElement.contains(this.element) ) {
      // check we're actually still in the DOM
      fetchWithCSRFToken(this.refreshUri, { method: 'patch'})
        .then(async (response) => {
          if (response.ok) return

          if (response.status === 403 && !this.submitted) {
            customModal.showModal('Bearbeitung abgebrochen', await response.text(), this.abortUri)
            this.stopRefreshing()
          }
          this.failCount += 1
          if (this.failCount > 3) {
            this.stopRefreshing() // avoid a million errors
          }
        })
    } else {
      this.stopRefreshing()
    }
  }
}

up.compiler('[record-lock-refresher]', (element, options) => {
  let recordLockRefresher = new RecordLockRefresher(element, options)
  recordLockRefresher.startRefreshing()

  element.closest('form').addEventListener('submit', () => {
    recordLockRefresher.submitted = true
  })

  return () => {
    if (!recordLockRefresher.submitted) {
      recordLockRefresher.release()
    }
    recordLockRefresher.stopRefreshing()
  }
})
