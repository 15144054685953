up.compiler('[address]', (element) => {

  function openMap() {
    let address = getAddress()

    if (address.length > 0) {
      let url = 'https://www.google.de/maps/search/' + encodeURIComponent(address)
      window.open(url)
    } else {
      alert('Bitte geben Sie zuerst eine Adresse ein.')
    }
  }

  function getAddress() {
    return Array.from(element.querySelectorAll('input[type=text]'))
      .map((element) => {
        return element.value
      })
      .join(' ')
      .trim()
  }

  const button = up.element.createFromHTML('<button type="button" class="btn btn-secondary mt-1"><span class="fa-solid fa-location-dot"></span><span class="icon-text">In Google Maps öffnen</span></button>')

  element.appendChild(button)
  button.addEventListener('click', openMap)

})
