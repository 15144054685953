import tours from '../modules/tours'

tours.add('exports', [
  {
    title: 'Exporte',
    text: `
      <p>Sie befinden sich im Exportbereich.</p>
      <p>Dieser umfasst Excel-Downloads für Daten, die nicht zum Bereich „Auswertungen“ gehören.</p>
      <p>Exporte können bei Bedarf heruntergeladen und nach eigenen Kriterien in Anwendungen wie MS Excel ausgewertet werden.</p>
    `,
  },
  {
    attachTo: {
      element: '.master-data-exports',
      on: 'top',
    },
    title: 'Stammdaten-Exporte',
    text: `
      <p>Hier finden Sie Exporte von im System gespeicherten Stammdaten.</p>
      <p>Bitte beachten Sie, dass die Erstellung von Stammdaten-Exporten mehrere Sekunden in Anspruch nehmen kann.</p>
    `,
  },
  {
    attachTo: {
      element: '.bike-education-exports',
      on: 'top',
    },
    title: 'RFA-Exporte',
    text: `
      <p>Hier finden Sie Exporte von Radfahrausbildungen.</p>
      <p>Bitte beachten Sie, dass die Erstellung von RFA-Exporten mehrere Sekunden in Anspruch nehmen kann.</p>
    `,
  },
  {
    attachTo: {
      element: '.budget-exports',
      on: 'top',
    },
    title: 'Budget-Exporte',
    text: `
      <p>Hier finden Sie Exporte von Budgets.</p>
      <p>Bitte beachten Sie, dass die Erstellung von Budget-Exporten mehrere Sekunden in Anspruch nehmen kann.</p>
    `,
  },
  {
    attachTo: {
      element: '.newsletter-exports',
      on: 'top',
    },
    title: 'Newsletter',
    text: `
      <p>Hier können Sie Listen mit E-Mail-Adressen aller Newsletter-Abonnenten herunterladen.</p>
    `,
  },
])
