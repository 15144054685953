import insertAtCaret from '../modules/insert_at_caret'

up.compiler('.link-insertable', (element, data) => {

  let linkInserter = document.createElement('span')
  linkInserter.className = 'link-insertable--button btn btn-secondary btn-sm'
  linkInserter.innerHTML = 'Link einfügen'

  let textarea = element.querySelector('textarea')
  element.insertBefore(linkInserter, textarea)

  linkInserter.addEventListener('click', (event) => {
    let dummyLink = '[Link-Titel](' + data.url + ')'
    insertAtCaret(textarea, dummyLink)
  })

})
