up.feedback.config.currentClasses.push('active')

up.form.config.groupSelectors.unshift('.form-group')
up.form.config.watchInputDelay = 150
up.form.config.submitSelectors.push('form')

up.fragment.config.runScripts = true

up.history.config.updateMetaTags = true

up.link.config.preloadDelay = 100
up.link.config.followSelectors.push('a[href]')
up.link.config.instantSelectors.push('a[href]')
up.link.config.noInstantSelectors.push('.btn:not(.btn-link)')
up.link.config.preloadSelectors.push('a[href]')

up.viewport.config.fixedTopSelectors.push('.sticky-top')
up.viewport.config.fixedTopSelectors.push('.fixed-top')
up.viewport.config.fixedTopSelectors.push('.navigation')
up.viewport.config.duration = 300
up.viewport.config.revealSnap = 100

up.on('up:layer:open', () => { document.body.classList.add('-modal-open') })
up.on('up:layer:dismiss', () => {
  if (up.layer.isRoot()) {
    // remove class only when last overlay is closed
    document.body.classList.remove('-modal-open')
  }
})

if (document.body.getAttribute('data-environment') === 'test') {
  // Disable animations globally so Capybara doesn't see duplicate elements during transitions
  up.motion.config.enabled = false
  // When revealing elements, don't animate the scrolling
  up.viewport.config.duration = 0
  // Lower the watchInputDelay, so that tests don't have to wait so long
  up.form.config.watchInputDelay = 50
}
