up.compiler('[warn-if-present]', (element) => {

  let formGroup = element.closest('.form-group')
  let label = undefined

  if (!formGroup.closest('label')) {
    // If the element's form group contains no label, we explicitly look up the
    // label to make sure it receives the "text-warning" class.
    label = document.querySelector(`label[for="${element.id}"]`)
  }

  function onChange() {
    const value = element.value
    const present = value !== undefined && value.length > 0
    element.classList.toggle('is-warning', present)
    label?.classList.toggle('text-warning', present)
  }

  onChange()
  element.addEventListener('change', onChange)
  element.addEventListener('input', onChange)

  return () => {
    element.removeEventListener('change', onChange)
    element.removeEventListener('input', onChange)
  }
})
