up.compiler('.collapsible', (element) => {

  let ATTRIBUTE_NAME = 'data-collapsed'

  function getCollapsed() {
    return !!element.getAttribute(ATTRIBUTE_NAME)
  }

  function setCollapsed(makeCollapsed) {
    if (makeCollapsed) {
      element.setAttribute(ATTRIBUTE_NAME, true)
    } else {
      element.removeAttribute(ATTRIBUTE_NAME)
    }
  }

  function toggle() {
    setCollapsed(!getCollapsed())
  }

  const collapsibleToggle = element.querySelector('.collapsible--toggle')
  collapsibleToggle.addEventListener('click', toggle)

  return () => {
    collapsibleToggle.removeEventListener('click', toggle)
  }

})
