up.compiler('[change-calendar-view]', (element) => {

  element.addEventListener('change', () => {
    const url = new URL(location.href)
    const searchParams = new URLSearchParams(url.search)
    if (element.value === 'Monat') {
      searchParams.set('view', 'month')
    } else {
      searchParams.set('view', 'week')
    }
    url.search = searchParams.toString()
    up.reload('.calendar', { url: url.toString() })
    window.history.pushState('', document.title, url.toString())
  })

})
