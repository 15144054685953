up.compiler('[clickable-row]', (element) => {
  up.on(element, 'click', (event) => {
    if (event.target.closest('a, [checkable-cell]')) {
      // User clicked directly on a link or interactable table cell
      return
    }

    let anchor = element.querySelector('a')

    if (anchor) {
      if(anchor.getAttribute('target')){
        anchor.click()
      } else {
        up.follow(anchor)
      }
    }
  })
})
