import detectMobile from 'ismobilejs'
import { format, parse } from 'fecha'
import TinyDatePicker from 'tiny-date-picker'

const SERVER_FORMAT = 'YYYY-MM-DD'

export default class DatePicker {

  constructor({ elements, onDatePicked, initialDate, hilightedDate, withDayOfWeek, startView }) {
    this.inputGroup = elements.inputGroup
    this.dateInput = elements.dateInput
    this.onDatePicked = onDatePicked
    this.datePicker = undefined
    this.isMobile = detectMobile().any
    this.originalInput = elements.originalInput
    this.picker_format = withDayOfWeek ? 'dddd, DD.MM.YYYY' : 'DD.MM.YYYY'
    this.isMobile ? this._registerMobilePicker() : this._registerDesktopPicker()
    this.setDate(initialDate)
    this.hilightedDate = hilightedDate
    this.startView = startView
  }

  setDate(date) {
    if (this.datePicker) {
      this.datePicker.setState({ selectedDate: date })
    } else {
      this.dateInput.value = date && format(date, SERVER_FORMAT) || ''
    }
  }

  setHilightedDate(date) {
    if (this.datePicker) {
      this.datePicker.setState({ hilightedDate: date })
    }
  }

  destroy() {
    if (this.datePicker) up.util.task(this.datePicker.destroy)
  }

  _registerMobilePicker() {
    this.inputGroup.classList.add('date-group')
    this.dateInput.type = 'date'
    this.dateInput.addEventListener('input', () => { this.onDatePicked(parse(this.dateInput.value, SERVER_FORMAT)) })
  }

  _registerDesktopPicker() {
    const dateInput = this.dateInput
    let pickerOpen = false

    const datePickerOptions = {
      mode: 'dp-below',
      format: (date) => format(date, this.picker_format),
      parse: (string) => parse(string, this.picker_format) || parse(string, 'D.M.YYYY') || parse(string, 'D.M.YY') || parse(string, 'D.M') || parse(string, 'YYYY-MM-DD') || new Date(),
      dayOffset: 1, // Week starts on Monday
      lang: {
        days: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        today: 'Auf heute setzen',
        clear: 'Feld leeren',
        close: 'Abbrechen',
      },
    }

    if (this.originalInput.closest('up-modal')) {
      datePickerOptions.appendTo = this.originalInput.closest('up-modal')
    }

    this.datePicker = TinyDatePicker(dateInput, datePickerOptions)

    this.datePicker
      .on('select', (event, picker) => {
        const date = picker.state.selectedDate
        this.onDatePicked(date)

        if (clickedInsidePicker()) date ? this._focusNext(dateInput) : dateInput.focus()
      })
      .on('open', () => {
        pickerOpen = true
        if (this.hilightedDate) {
          this.datePicker.setState({ hilightedDate: this.hilightedDate })
        }

        if (this.startView && this.startView !== 'day') {
          this.datePicker.setState({ view: this.startView })

          if (this.startView === 'year') {
            // The datepicker offers 201 year options. Scroll to the currently highlighted year.
            const highlighted = document.querySelector('.dp-year.dp-current')
            highlighted.scrollIntoView({ block: 'nearest' })
          }
        }

      })
      .on('close', () => {
        explicitlyPickDate.apply(this)
        pickerOpen = false
      })

    dateInput.addEventListener('keypress', (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        explicitlyPickDate.apply(this)
        this._focusNext(dateInput)
      }
    })

    dateInput.addEventListener('input', () => {
      pickerOpen || this.datePicker.open()
    })

    dateInput.addEventListener('focus', () => {
      if (!pickerOpen)
        dateInput.select()
    })

    function explicitlyPickDate() {
      if (dateInput.value) {
        this.setDate(this.datePicker.state.hilightedDate)
      } else {
        this.onDatePicked(null)
      }
    }

    function clickedInsidePicker(element = document.querySelector(':focus')) {
      return element && element.closest('.dp-cal')
    }
  }

  _focusNext(currentInput) {
    const form = currentInput.form
    let formElements = Array.from(form ? form.elements : document.querySelectorAll('input, textarea, select, button'))
    formElements = formElements.slice(formElements.indexOf(currentInput) + 1)

    const nextElement = formElements.filter(this._focusableElement)[0]
    if (nextElement && nextElement.ariaLabel?.endsWith(" (Uhrzeit)")) nextElement.focus()
  }

  _focusableElement(element) {
    return !element.disabled && element.type !== 'hidden' && !element.hidden && (element.tabindex === undefined || element.tabindex > -1)
  }
}
