// A fieldset[disabled] stops propagation of mouse events in Firefox.
// Event-bound code like an `[up-modal]` compiler would not work.
//
// There is no way to change this in Firefox. We use a `no-edit` attribute
// instead, and disable all fields manually.
//

let noEditInputs = up.form.config.fieldSelectors.map(field => `[no-edit] ${field}`).join(', ')

up.compiler(noEditInputs, function(element) {
  element.disabled = true
})
