import customModal from '../modules/custom_modal'

up.compiler('[update-on-state-change]', (element, data) => {
  let url = data.url
  let microseconds = data.interval * 1000
  let timeout = undefined
  if (isNaN(microseconds)) alert(`Bad schedule interval: ${data.interval}`)

  function fetchResponse() {
    reset()
    fetch(url)
      .then(async (response) => {
        if (response.ok) {
          updateElement(await response.text())
        } else {
          schedule()
        }
      })
      .catch(schedule)
  }

  function updateElement(state) {
    let badge = element.querySelector('.badge')
    let currentState = badge.innerText
    if (currentState !== state) {
      customModal.showModal('Statuswechsel', `Der Status dieses Vorgangs wurde auf „${state}“ geändert!`)
      reset()
    } else {
      schedule()
    }
  }

  function schedule() {
    timeout = setTimeout(fetchResponse, microseconds)
  }

  function reset() {
    clearTimeout(timeout)
  }

  fetchResponse()

  return () => {
    reset()
  }
})
