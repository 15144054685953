import tours from './modules/tours'

document.addEventListener('keydown', function(event) {
  switch (event.which) {
    case 112: // F1
      let helpButton = document.querySelector('.help-button')
      if (helpButton && !up.modal.isOpen()) {
        helpButton.click()
      }
      return false
  }
})

up.on('up:location:changed', tours.endCurrentTour)
