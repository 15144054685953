// Bootstrap supports a group of radiobuttons/checkboxes styles as button group,
// but its stylesheet definitions expect the .btn-group container to have a
// [data-toggle="buttons"] attribute.
//
// Hence, we use that attribute and define an Unpoly compiler that responds to
// this attribute and flags the active label "button" as required.
//
up.compiler('[data-toggle="buttons"]', (element) => {

  function updateButtons() {
    element.querySelectorAll('.btn').forEach(updateButton)
  }

  function updateButton(button) {
    let checked = button.querySelector('input').checked

    button.ariaPressed = checked
    button.classList.toggle('active', checked)
  }

  up.on(element, 'change', 'input', updateButtons)

  updateButtons()
})
