up.compiler('[delete-icon-button]', (element, { target, confirmMessage }) => {
  const destroyCheckbox = element.querySelector('input')

  up.on(element, 'click', () => {
    if (!confirmMessage || confirm(confirmMessage)) {
      destroyCheckbox.checked = !destroyCheckbox.checked
      destroyCheckbox.value = destroyCheckbox.value === 'true' ? 'false' : 'true'
      up.validate(destroyCheckbox, { target })
    }
  })
})
