// Lock capybara tests when changes to this form or field are observed
// The given options should be the options that are passed to up.watch or up.autosubmit
// You can define an extra `taskName` option that shows up in CapybaraLockstep's debug output
// This function is always available, but only executes if CapybaraLockstep is defined (i.e. in tests)
export function lockCapybaraForObservedChanges(formOrField, options) {
  if (window.CapybaraLockstep) {
    const taskName = options.taskName || `observed changes in ${formOrField}`
    const delay = options.delay || formOrField.getAttribute('up-delay') || up.form.config.watchInputDelay
    return up.on(formOrField, 'input change', () => {
      window.CapybaraLockstep.startWork(taskName)
      setTimeout(() => window.CapybaraLockstep.stopWork(taskName), delay)
    })
  }
}

if (window.CapybaraLockstep) {
  // lock capybara for all changes to fields with callbacks
  up.compiler('[up-observe]', (formOrField) => {
    return lockCapybaraForObservedChanges(formOrField, { taskName: `[up-observe]: ${formOrField}` })
  });
  up.compiler('[up-autosubmit]', (formOrField) => {
    return lockCapybaraForObservedChanges(formOrField, { taskName: `[up-autosubmit]: ${formOrField}` })
  });
  up.compiler('[up-validate]', (formOrField) => {
    return lockCapybaraForObservedChanges(formOrField, { taskName: `[up-validate]: ${formOrField}` })
  });
}
