import visibility from '../modules/visibility'

up.compiler('[new-messages-count]', (element, data) => {
  let url = data.url
  let microseconds = data.interval * 1000
  let visibleForZero = data.visibleForZero
  let timeout = undefined

  if (isNaN(microseconds)) alert(`Bad schedule interval: ${data.interval}`)

  function fetchCount() {
    reset()
    fetch(url)
      .then(async (response) => {
        if (response.ok) {
          updateElement(await response.text())
        } else {
          schedule()
        }
      })
      .catch(schedule)
  }

  function updateElement(count) {
    let title = count == 1 ? `${count} neue Nachricht` : `${count} neue Nachrichten`

    element.innerText = count
    element.setAttribute('title', title)

    up.element.toggle(element, visibleForZero || count > 0)

    schedule()
  }

  function schedule() {
    timeout = setTimeout(fetchCount, microseconds)
  }

  function reset() {
    clearTimeout(timeout)
  }

  up.on('app:update-new-messages-count app:visible', fetchCount)
  up.on('app:hidden', reset)

  if (!visibility.hidden()) fetchCount()

  return () => {
    up.off('app:update-new-messages-count app:visible', fetchCount)
    up.off('app:hidden', reset)
    reset()
  }

})
