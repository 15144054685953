function fadeIn(element) {
  setTimeout(() => {
    element.classList.add('visible')
  })
}

up.compiler('.fade-in', fadeIn)

// If Unpoly is unavailable (e.g. failed sign-in POST-form where Unpoly won't boot), make sure elements still fade in
document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.fade-in:not(.visible)').forEach(fadeIn)
})
