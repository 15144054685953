up.compiler('input[type=file]', (element) => {

  element.addEventListener('change', function() {
    let filename = String(element.value)
    let basename = filename.replace(/^.*[\\\/]([^\\\/]+)$/, '$1')

    element.closest('.custom-file').querySelector('.custom-file-label').innerText = basename
  })

})
