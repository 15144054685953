up.compiler('[dropdown]', (element) => {

  const ARIA_ATTRIBUTE_NAME = 'aria-expanded'
  const EXPANDED_TRUE_VALUE = 'true'
  const EXPANDED_FALSE_VALUE = 'false'

  element.setAttribute('aria-haspopup', 'true')

  function getExpanded() {
    return element.getAttribute(ARIA_ATTRIBUTE_NAME) === EXPANDED_TRUE_VALUE
  }

  function setExpanded(isExpanded) {
    element.setAttribute(ARIA_ATTRIBUTE_NAME, isExpanded ? EXPANDED_TRUE_VALUE : EXPANDED_FALSE_VALUE)
    element.parent.classList.toggle('show', isExpanded)
  }

  function show() {
    setExpanded(true)
  }

  function hide() {
    setExpanded(false)
  }

  function toggle() {
    getExpanded() ? hide() : show()
  }

  function onBodyClick(event) {
    if (!event.target.closest('[dropdown]')) hide()
  }

  element.addEventListener('click', toggle)

  document.body.addEventListener('click', onBodyClick)
  return () => { document.body.removeEventListener('click', onBodyClick) }

})
