import DatePicker from '../modules/date_picker'
import TimePicker from '../modules/time_picker'
import { format, parse } from 'fecha'

// Date or DateTime picker component.
//
// Introduces date and (optional) time fields in favor of a simple <input> field.
// The original input is hidden and updated whenever the user interacts with those pickers.
//
// Usage examples:
//
//     Datetime field which becomes both date picker and time picker
//     <input type="text" up-data='{ "date": true, "time": true }'>
//
//     Date field which becomes a date picker
//     <input type="text" up-data='{ "date": true, "time": false }'>
//
// The application's DateTimeInput will set the correct options for its form fields automatically.
//
up.compiler('[datetime-picker]', (element, { date, time, initialDate, withDayOfWeek, labelText, startView }) => {
  const SERVER_FORMAT = time ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'

  let currentDateTime = parse(element.value, SERVER_FORMAT)

  let datePicker
  let timePicker

  function init() {
    const invalid = element.matches('.is-invalid')
    const disabled = element.disabled

    const inputGroup = up.element.createFromSelector('.input-group')

    if (date) {
      const dateId = `${element.id}-date-input`
      const dateInput = up.element.affix(inputGroup, 'input.form-control.date-input[type=text][name=date:unobservable_attribute]', { 'aria-label': `${labelText} (Datum)`, placeholder: element.placeholder || '__.__.____', id: dateId })
      dateInput.classList.toggle('is-invalid', invalid)
      dateInput.disabled = disabled

      const dateAddon = up.element.affix(inputGroup, '.input-group-prepend[type=button] .input-group-text .fas.fa-calendar-days')
      dateAddon.addEventListener('click', () => { dateInput.focus() })

      inputGroup.classList.add('date-group')

      const elements = {
        inputGroup: inputGroup,
        dateInput: dateInput,
        originalInput: element,
      }

      const hilightedDate = initialDate = parse(initialDate, 'YYYY-MM-DD')
      datePicker = new DatePicker({ elements, onDatePicked, initialDate: currentDateTime, hilightedDate, withDayOfWeek, startView })
      dateInput.datePicker = datePicker
    }

    if (time) {
      const timeId = `${element.id}-time-input`
      const timeInput = up.element.affix(inputGroup, 'input.form-control.time-input[type=text][name=time:unobservable_attribute]', { 'aria-label': `${labelText} (Uhrzeit)`, placeholder: '__:__', id: timeId })
      timeInput.classList.toggle('is-invalid', invalid)
      timeInput.disabled = disabled

      const timeAddon = up.element.affix(inputGroup, '.input-group-append[type=button] .input-group-text .fas.fa-clock')
      timeAddon.addEventListener('click', () => { timeInput.focus() })

      timePicker = new TimePicker({ timeInput, onTimePicked, initialTime: currentDateTime })
    }

    element.after(inputGroup)
    element.type = 'hidden'

    const primaryInputId = `${element.id}-${date ? 'date' : 'time'}-input`
    document.querySelectorAll(`label[for=${element.id}]`).forEach((label) => {
      label.setAttribute('for', primaryInputId)
    })
  }

  function updateElementValue() {
    const currentValue = element.value
    element.value = currentDateTime ? format(currentDateTime, SERVER_FORMAT) : ''

    if (currentValue !== element.value) {
      up.emit(element, 'change')
    }
  }

  function onDatePicked(newDate) {
    if (newDate) {
      initializeCurrentDate()
      currentDateTime.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate())
    } else {
      currentDateTime = undefined
      if (timePicker) timePicker.setTime(null)
    }
    updateElementValue()
    if (window.CapybaraLockstep?.waitForPickDate) {
      window.CapybaraLockstep.waitForPickDate = false
      window.CapybaraLockstep.stopWork('pickDate')
    }
  }

  function onTimePicked(newTime) {
    if (newTime) {
      initializeCurrentDate()
      currentDateTime.setHours(newTime ? newTime.getHours() : 0)
      currentDateTime.setMinutes(newTime ? newTime.getMinutes() : 0)
      currentDateTime.setSeconds(0)
    } else {
      currentDateTime = undefined
    }
    updateElementValue()
  }

  function initializeCurrentDate() {
    if (!currentDateTime) {
      // Initialize with today at 00:00
      currentDateTime = new Date()
      currentDateTime.setHours(0)
      currentDateTime.setMinutes(0)
      currentDateTime.setSeconds(0)
    }
  }

  init()
  return function onDestroy() {
    if (datePicker) datePicker.destroy()
    if (timePicker) timePicker.destroy()
  }

})
