import tours from '../modules/tours'

tours.add('dashboard', [
  {
    title: 'Startseite',
    text: `
    <p>Sie befinden sich auf Ihrer persönlichen Startseite.</p>
      <p>Diese liefert Ihnen einen Überblick über anstehende Tätigkeiten und kann individuell konfiguriert werden.</p>
    `,
  },
  {
    attachTo: {
      element: '.content',
      on: 'top',
    },
    title: 'Startseite',
    text: `
      <p>Im Inhaltsbereich sind Kacheln sichtbar, die unterschiedliche Kennwerte anzeigen.</p>
    `,
  },
  {
    attachTo: {
      element: '.row div:nth-child(2) .dashboard-element',
    },
    title: 'Startseite',
    text: `
      <p>Einzelne Elemente zeigen immer einen bestimmten Wert.</p>
      <p>Ein Button führt Sie direkt zum zugehörigen Anwendungsbereich.</p>
    `,
  },
  {
    attachTo: {
      element: '.edit-dashboard-button',
    },
    title: 'Startseite personalisieren',
    text: `
      <p>Klicken Sie auf diesen Button, um die anzuzeigenden Elemente zu wählen.</p>
      <p>Die Anwendung merkt sich die von Ihnen gewählten Startseiten-Elemente und zeigt diese beim nächsten Besuch wieder an.</p>
    `,
  },
])
