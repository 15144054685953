function showModal(title, message, path = undefined) {
  let url = window.location.pathname

  if (typeof path !== 'undefined') {
    url = path
  }

  const html = `
    <div class="custom-modal">
      <div class="modal-header">
        <h5 class="modal-title">${title}</h5>
      </div>
      <div class="modal-body">
        <p>
          ${message}
        </p>
      </div>
      <div class="modal-footer">
        <a href="${url}" up-layer="root" class="btn btn-primary">Zurück</a>
      </div>
    </div>
  `

  up.layer.open({ fragment: html, dismissable: false })
}

module.exports = {
  showModal: showModal
}
