import insertAtCaret from '../modules/insert_at_caret'

function openList(templatable) {
  const list = up.element.createFromSelector('.templatable--list')
  up.element.hide(list)
  const textarea = templatable.querySelector('textarea')
  textarea.parentElement.insertBefore(list, textarea)

  up.render({ target: '.templatable--list', url: '/text_templates/templatable' })

  templatable.querySelector('.templatable--menu-opener').classList.add('active')
}

function closeLists() {
  up.destroy('.templatable--list')
  document.querySelectorAll('.templatable--menu-opener').forEach((menuOpener) => {
    menuOpener.classList.remove('active')
  })
}

up.compiler('.templatable', (templatable) => {
  if (!window.Power.templatable_text_templates) { return }
  if (templatable.closest('[no-edit]')) { return }
  if (templatable.querySelector('textarea[disabled]')) { return }

  const opener = up.element.createFromHTML('<button type=button class="templatable--menu-opener btn btn-secondary btn-sm">Textbaustein einfügen</button>')
  opener.addEventListener('click', (event) => {
    if (opener.classList.contains('active')) {
      closeLists()
    } else {
      closeLists()
      openList(templatable)
    }
  })

  if (templatable.querySelector('label.sr-label')) {
    const spacer = up.element.createFromSelector('.templatable--spacer')
    templatable.prepend(spacer)
    templatable.prepend(opener)
  } else {
    const textarea = templatable.querySelector('textarea')
    textarea.parentElement.insertBefore(opener, textarea)
  }
})

up.compiler('.templatable--copy', (link, data) => {
  link.addEventListener('click', (event) => {
    const textarea = event.currentTarget.closest('.templatable').querySelector('textarea')

    closeLists()
    insertAtCaret(textarea, data.text)
  })
})
