up.compiler('[counts-card-contents]', (element) => {

  let selector = element.getAttribute('counts-card-contents')
  element.querySelector('.card-header').append(up.element.createFromHTML('<span class="counter" />'))
  const counter = element.querySelector('.counter')

  function updateCounter() {
    let count = Array.from(element.querySelectorAll(selector))
      .filter(function(child) { return !child.closest('.up-destroying') })
      .length
    counter.innerText = ` (${count})`
  }

  function onFragmentUpdate(event, fragment) {
    if (element.contains(fragment)) {
      updateCounter()
    }
  }

  updateCounter()

  return up.on('up:fragment:inserted', onFragmentUpdate)
})
