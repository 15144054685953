up.compiler('[full-width]', (element) => {
  const fullWidthClass = '-full-width'
  const contentContainer = document.querySelectorAll('.container')

  contentContainer.forEach((container) => {
    container.classList.add(fullWidthClass)
  })

  function remove() {
    contentContainer.forEach((container) => {
      container.classList.remove(fullWidthClass)
    })
  }

  return remove
})
