import Cleave from 'cleave.js'
import { format, parse } from 'fecha'
import detectMobile from 'ismobilejs'

export default class TimePicker {

  constructor({ timeInput, onTimePicked, initialTime }) {
    this.timeInput = timeInput
    this.onTimePicked = onTimePicked
    this.timePicker = undefined
    this.native = detectMobile().any
    this._registerTimePicker()
    this.setTime(initialTime)
  }

  setTime(dateTime) {
    this.timeInput.value = (dateTime && format(dateTime, 'HH:mm')) || ''
  }

  destroy() {
    if (this.timePicker) this.timePicker.destroy()
  }

  _registerTimePicker() {
    const timeInput = this.timeInput

    if (this.native) {
      timeInput.type = 'time'
    } else {
      this.timePicker = new Cleave(timeInput, {
        time: true,
        timePattern: ['h', 'm'],
        timeFormat: '24',
      })
    }

    function getZeroPaddedString() {
      const inputValue = timeInput.value
      const [hourString, minuteString] = [...inputValue.split(':'), '', ''].slice(0, 2)
      return [hourString, minuteString].map((number) => `00${number}`.substr(-2)).join(':')
    }

    timeInput.addEventListener('blur', () => {
      if (timeInput.value.length > 0) {
        timeInput.value = getZeroPaddedString()
      }
    })

    timeInput.addEventListener('input', () => {
      this.onTimePicked(parse(getZeroPaddedString(), 'HH:mm'))
    })

    timeInput.addEventListener('focus', () => {
      timeInput.select()
    })
  }

}
