// This compiler sets two different classes on the subnavigation, -active and -open.
// On wide screens a subnavigation is shown if it is either active or open.
// On mobile screens the -active class does nothing and an -open subnavigation is shown as a dropdown.
// (see navigation.sass)
//

function activateSubnavigation(subnavigation) {
  const oldNavigation = document.querySelector('.navigation--secondary-menu.-active')

  oldNavigation?.classList.remove('-active')
  subnavigation?.classList.add('-active')

  oldNavigation?.querySelectorAll('.nav-link').forEach((element) => {
    element.setAttribute('tabindex', '-1')
  })
  subnavigation?.querySelectorAll('.nav-link').forEach((element) => {
    element.removeAttribute('tabindex')
  })
}

function openOrCloseSubnavigationMenu(target) {
  const currentlyOpen = closeSubnavigationMenu()

  if (target !== currentlyOpen) {
    target.classList.add('-open')
  }

  const links = target.querySelectorAll('.nav-link')
  if (links.length === 1) {
    const link = links[0]
    setTimeout(() => {
      link.click()
      up.follow(link)
    })
  }
}

function closeSubnavigationMenu() {
  const openMenu = document.querySelector('.navigation--secondary-menu.-open')
  openMenu?.classList.remove('-open')
  return openMenu
}

up.compiler('.navigation', (navigation) => {
  let scrollbarWidth = window.innerWidth - navigation.getBoundingClientRect().width
  navigation.style.width = `calc(100vw - ${scrollbarWidth}px`

  function setActiveSubnavigation() {
    setTimeout(() => {
      closeSubnavigationMenu()

      // This is basically .navigation--secondary-menu:has(.up-current), but as of 2022-10, :has not supported enough
      activateSubnavigation(navigation.querySelector('.navigation--secondary-menu .up-current')?.closest('.navigation--secondary-menu'))
    })
  }

  function onClickDocument(event) {
    if (event.target.closest('.shepherd-element, .shepherd-modal-overlay-container')) return
    if (!navigation.contains(event.target) || event.target === navigation) setActiveSubnavigation()
  }

  function onClickNavigation(event) {
    // Clicking a .navigation--spacer inside the primary menu should revert any switched secondary menu.
    // The event won't target .navigation--spacer, but hit .navigation--row instead.
    if (event.target.matches('.navigation--primary-menu .navigation--row')) setActiveSubnavigation()
  }

  navigation.addEventListener('click', onClickNavigation)
  up.on('click', onClickDocument)

  setActiveSubnavigation()
  up.on('up:location:changed', setActiveSubnavigation)

  return () => {
    up.off('click', onClickDocument)
    up.off('up:location:changed', setActiveSubnavigation)
  }

})

up.macro('.navigation--primary-menu a, .navigation--primary-menu button', (element, subsectionSelector) => {
  if (typeof subsectionSelector !== 'string') return
  const subsection = document.querySelector(subsectionSelector)

  element.setAttribute('title', element.innerText.replace(/\s+/g, ' ').trim())

  element.addEventListener('click', () => {
    activateSubnavigation(subsection)
    openOrCloseSubnavigationMenu(subsection)
  })

})
