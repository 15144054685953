up.compiler('[confirm-unsaved-changes="true"]', (element) => {
  const confirmationMessage = 'Ungespeicherte Inhalte und Änderungen verwerfen?'
  let unsavedChanges = false

  function onChange() {
    unsavedChanges = true
  }

  function onSubmit() {
    unsavedChanges = false
  }

  function mayLeave(event) {
    return !unsavedChanges || event.target.hasAttribute('disabled-unsaved-change-confirmation') || window.confirm(confirmationMessage)
  }

  function confirmLeavingPage(event) {
    const targetOpensModal = event.target.matches('[up-layer~="new"]')
    if (!targetOpensModal && !mayLeave(event)) event.preventDefault()
  }

  function confirmClosingModal(event) {
    const formInsideModal = element.closest('up-modal')
    if (formInsideModal && !mayLeave(event)) event.preventDefault()
  }

  function confirmRefreshingPage(event) {
    if (unsavedChanges) {
      event.preventDefault()
      event.returnValue = confirmationMessage
    }
  }

  return [
    up.watch(element, { delay: 0, batch: true }, onChange),
    up.on('up:form:submit up:form:validate', onSubmit),
    up.on('up:link:follow', confirmLeavingPage),
    up.on('up:layer:dismiss', confirmClosingModal),
    up.on(window, 'beforeunload', confirmRefreshingPage),
  ]
})
