// Users want to navigate field tables vertically.
//
// This compiler assigns tabindex for all inputs, anchors, and buttons inside a [vertical-tabbing] element.
// Note that tabindex are not unique across the page, and that pressing the tab key at the end of such a section will
// likely not focus the next field (because that probably has a tabindex of 0). This is okay for us.
//
// The compiler assigns tabindexes between 10000 and 19999, like so:
//
//   | 10000 | 10100 | 10200 |
//   | 10001 | 10101 | 10201 |
//   | 10002 | 10101 | 10202 |
//
// If elements have a tabindex, it will not be changed.
//
up.compiler('[vertical-tabbing]', (element) => {
  element.querySelectorAll('tr').forEach(function(row, rowIndex) {
    row.querySelectorAll('td').forEach(function(cell, cellIndex) {
      cell.querySelectorAll('input, select, textarea, a, button').forEach(function(field) {

        if (!field.getAttribute('tabindex')) {
          field.setAttribute('tabindex', `1${String(cellIndex).padStart(2, '0')}${String(rowIndex).padStart(2, '0')}`)
        }
      })
    })
  })
})
