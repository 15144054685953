up.compiler('.contact-person', (element) => {
  const unbind = up.on('click', '[autofill-contact-person]', (event, $button, data) => {

    for (let [key, value] of Object.entries(data)) {
      const input = element.querySelector(`[id$="${key}"]`)
      input.value = value
      // change updates tom-select values
      up.emit(input, 'change')
    }
  })

  return unbind
})
