up.compiler('form:not([enable-enter])', (form) => {
  up.on(form, 'keypress', 'input', (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      focusNextTabbable(event.target)
    }
  });
});

function focusNextTabbable(currentElement) {
  let sortedTabbables = Array.from(document.querySelectorAll('input, a, select, button, textarea'))
    .map(function (element, index) {
      // Remember DOM order to avoid reordering elements with the same tabindex
      return {element: element, tabIndex: element.tabIndex, listIndex: index}
    })
    .sort(function (item1, item2) {
      if (item1.tabIndex === item2.tabIndex) {
        return item1.listIndex - item2.listIndex
      } else {
        return item1.tabIndex - item2.tabIndex
      }
    })

  let nextIndex = sortedTabbables.findIndex(function (item) {
    return item.element === currentElement
  }) + 1

  if (nextIndex < sortedTabbables.length) {
    sortedTabbables[nextIndex].element.focus()
  } else {
    sortedTabbables[0].element.focus()
  }
}
