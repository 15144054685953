import tours from '../modules/tours'

tours.add('bike_education_bill_show', [
  {
    title: 'Abrechenbare Radfahrausbildung',
    text: `
      <p>Sie befinden sich in der Leseansicht einer Direktabrechnung.</p>
      <p>Hier können Sie die Abrechnung einsehen, ohne Änderungen vorzunehmen.</p>
    `,
  },
  {
    attachTo: {
      element: '[no-edit]',
      on: 'top',
    },
    title: 'Abrechenbare Radfahrausbildung',
    text: `
      <p>Im Inhaltsbereich wird Ihnen das Abrechnungsformular im Lesemodus angezeigt.</p>
    `,
    scrollTo: false,
  },
  {
    attachTo: {
      element: '.collapsible[data-collapsed]',
    },
    title: 'Zusammengeklappte Elemente',
    text: `
      <p>Neben den Abrechnungsdaten werden einige weiterführende Informationen angezeigt; deren Abschnitte sind zunächst eingeklappt.</p>
      <p>Durch Klick auf den Titel eines Abschnitts kann dieser ausgeklappt (oder wieder eingeklappt) werden.</p>
      <p>Hinweis: Nur Abschnitte mit einem Symbol <span class="text-nowrap"><span class="fa-solid fa-caret-down"></span> bzw. <span class="fa-solid fa-caret-up"></span></span> können ausgeklappt bzw. eingeklappt werden.</p>
    `,
  },
  {
    attachTo: {
      element: '.question-button',
    },
    title: 'Rückfragen',
    text: `
      <p>Sollten sich Rückfragen ergeben, können Sie über diesen Button eine Rückfrage an die zuständige Ortsverkehrswacht stellen.</p>
    `
  },
  {
    attachTo: {
      element: '.edit-button',
    },
    title: 'Abrechnung bearbeiten',
    text: `
      <p>Zum Bearbeiten der Abrechnung klicken Sie auf den „Bearbeiten“-Button.</p>
    `
  },
  {
    attachTo: {
      element: '.snapshots-button',
    },
    title: 'Versionen',
    text: `
      <p>Über diesen Button können Sie vorige Zustände der Abrechnung einsehen.</p>
      <p>Dort kann z.B. die ursprünglich bei der LVW zur Prüfung eingereichte Abrechnung eingesehen werden.</p>
    `
  },
])
