import tours from '../modules/tours'

tours.add('bike_education_bills', [
  {
    title: 'Abrechnungen Radfahrausbildung',
    text: `
      <p>Sie befinden sich auf der Übersichtsliste für Abrechnungen.</p>
    `,
  },
  {
    attachTo: {
      element: '.bike-education-bills',
    },
    title: 'Abrechnungen Radfahrausbildung',
    text: `
      <p>Eine Liste der Abrechnungen wird hier angezeigt.</p>
      <p>Durch Anklicken eines Listeneintrags kann die entsprechende Abrechnung aufgerufen werden.</p>
    `,
  },
  {
    attachTo: {
      element: '.btn[href$="/bills/new"]',
    },
    title: 'Abrechnungen Radfahrausbildung',
    text: `
      <p>Eine neue Abrechnung können Sie über diesen Button erstellen.</p>
    `,
  },
  {
    attachTo: {
      element: 'form.filter',
      on: 'bottom',
    },
    title: 'Filter',
    text: `
      <p>Benutzen Sie Filter, um die angezeigte Auswahl einzugrenzen.</p>
    `,
  },
  {
    attachTo: {
      element: '.form-group[class*="filter_query"]',
    },
    title: 'Filter',
    text: `
      <p>Das Freitext-Suchfeld durchsucht Vorgangsnummer und Ansprechpartner.</p>
    `,
  },
  {
    attachTo: {
      element: '.form-group[class*="filter_date"]',
    },
    title: 'Filter',
    text: `
      <p>Benutzen Sie die Datumsfilter, um Abrechnungen anhand des Erstellungsdatums zu suchen.</p>
    `,
  },
  {
    attachTo: {
      element: '.form-group[class*="filter_states"]',
    },
    title: 'Filter',
    text: `
      <p>Zur weiteren Einschränkung können Kriterien wie Abrechnungsoption, Status, usw. gewählt werden.</p>
    `,
  },
  {
    attachTo: {
      element: 'form.filter',
      on: 'bottom',
    },
    title: 'Hinweis',
    text: `
      <p>Die Anwendung merkt sich die von Ihnen gewählten Filter.</p>
      <p>Wenn Sie diese Liste später erneut aufrufen, werden die zuletzt gewählten Filter automatisch wieder aktiviert.</p>
    `,
  },
])
