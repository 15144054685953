import tours from '../modules/tours'

tours.add('transactions', [
  {
    title: 'Buchungen',
    text: `
      <p>Sie befinden sich im Buchungsbereich.</p>
      <p>Buchungen werden von der Anwendung automatisch erstellt, wenn z.B. eine Veranstaltung abgerechnet wird.</p>
      <p>Diese werden durch Exportieren zur Überweisung angewiesen. Offene Buchungen warten noch auf den nächsten Zahlungslauf.</p>
    `,
  },
  {
    attachTo: {
      element: 'form.filter',
    },
    title: 'Buchungen',
    text: `
      <p>Benutzen Sie Filter, um die angezeigte Auswahl einzugrenzen.</p>
    `,
  },
  {
    attachTo: {
      element: '.transactions tbody > tr',
    },
    title: 'Buchungen',
    text: `
      <p>Durch Anklicken einer Buchung kann diese angezeigt werden.</p>
      <p>Dort sehen Sie dann auch Buchungsempfänger und den verknüpften Vorgang.</p>
    `,
    orphan: true,
  },
  {
    attachTo: {
      element: '.transactions [checkable-cell]',
      on: 'right',
    },
    title: 'Buchungen exportieren',
    text: `
      <p>Wählen Sie zu exportierende Buchungen aus, indem sie das jeweilige Kontrollkästchen anwählen.</p>
    `,
  },
  {
    attachTo: {
      element: '.transactions .sums-row',
      on: 'top',
    },
    title: 'Buchungen exportieren',
    text: `
      <p>Die Summe der zum Export gewählten Buchungen aktualisiert sich automatisch.</p>
    `,
  },
  {
    attachTo: {
      element: '.export-transactions-button',
    },
    title: 'Buchungen exportieren',
    text: `
      <p>Durch Klick auf diesen Button exportieren Sie die in der Liste gewählten Buchungen.</p>
      <p>Die Anwendung erstellt eine Export-Datei, die Sie anschließend herunterladen und in Datev importieren müssen.</p>
    `,
  },
])
