import visibility from './modules/visibility'
import { setGlobalDateI18n } from 'fecha'

document.addEventListener(visibility.changeEvent, () => {
  up.emit(visibility.hidden() ? 'app:hidden' : 'app:visible')
})

up.on('click', '.input-group-prepend, .input-group-append', (event, element) => {
  const previousElement = element.previousElementSibling
  const input = previousElement && previousElement.matches('input') ? previousElement : element.closest('.input-group').querySelector('input')
  if (input) input.focus()
})

// See https://makandracards.com/makandra/505751-better-numeric-inputs-in-desktop-browsers
up.compiler('input[type="number"]', (element) => {
  // Number inputs support exponential notation and in some browsers even regular letters. We don't want that
  element.addEventListener('keydown', (event) => {
    const key = event.key
    if (key.length === 1 && !event.ctrlKey && !event.altKey && !key.match(/[0-9\-+,.]/)) {
      event.preventDefault()
    }
  })

  // Some browser (at least Firefox) will increment/decrement a number when using the mouse's scroll wheel
  // above a focused number input. Since this can lead to users changing values by accident, we want to avoid that.
  element.addEventListener('wheel', (event) => {
    if (element.matches(':focus')) {
      event.preventDefault()
    }
  })
})

setGlobalDateI18n({
  dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
})
