const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

export default function fetchWithCSRFToken(url, options) {
  let body = options.body ?? new URLSearchParams()
  let headers = options.headers ?? {}

  if (!['get', 'post', undefined].includes(options.method)) {
    const realMethod = options.method
    options.method = 'post'
    body.set('_method', realMethod)
  }

  headers['X-CSRF-Token'] = token

  return fetch(
    url,
    Object.assign({}, options, { headers, body })
  )
}
