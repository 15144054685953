up.compiler('[change-calendar]', (element) => {

  const url = new URL(location.href)
  const searchParams = new URLSearchParams(url.search)
  element.value = searchParams.get('calendar')

  element.addEventListener('change', () => {
    const url = new URL(location.href)
    const searchParams = new URLSearchParams(url.search)
    searchParams.set('calendar', element.value)
    url.search = searchParams.toString()
    up.reload('.calendar', { url: url.toString() })
    window.history.pushState('', document.title, url.toString())
  })

})
