import tours from '../modules/tours'

tours.add('messages', [
  {
    title: 'Nachrichten',
    text: `
      <p>Sie befinden sich im Nachrichtenbereich.</p>
      <p>Nachrichten erhalten Sie z.B. bei Rückfragen zu Veranstaltungen oder automatisiert vom System.</p>
      <p>Einstellungen zu Systembenachrichtigungen (Statuswechsel, etc.) können Sie in Ihrem Benutzerprofil konfigurieren.</p>
    `,
  },
  {
    attachTo: {
      element: '.list-mode-buttons',
    },
    title: 'Nachrichten',
    text: `
      <p>Hier wechseln Sie zwischen empfangenen und gesendeten Nachrichten.</p>
    `,
  },
  {
    attachTo: {
      element: '.messages',
      on: 'top',
    },
    title: 'Nachrichten',
    text: `
      <p>Eine Liste aller empfangenen bzw. gesendeten Nachrichten wird hier angezeigt.</p>
    `,
  },
  {
    attachTo: {
      element: 'form[up-target=".messages"]',
    },
    title: 'Suche',
    text: `
      <p>Über das Suchfeld können Sie alle empfangenen oder gesendeten Nachrichten durchsuchen.</p>
    `,
  },
  {
    attachTo: {
      element: '.messages tbody > tr',
    },
    title: 'Suche',
    text: `
      <p>Öffnen Sie eine Nachricht, indem Sie sie anklicken.</p>
      <p>In der Nachrichtenansicht können Rückfragen beantwortet und verknüpfte Datensätze aufgerufen werden.</p>
    `,
    orphan: true,
  },
])
