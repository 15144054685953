up.compiler('.form-wizard-navigation--step[value="actions"]', (element) => {

  element.classList.add('state-actions-wrapper')

})

up.compiler('.form-actions', (element) => {

  if (element.querySelector('button[name*="state_event"]')) {
    element.classList.add('state-actions-wrapper')
  }

})
