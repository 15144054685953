up.compiler('[confirm]', (element) => {
  const text = element.getAttribute('confirm')

  function confirmClick(event) {
    if (!confirm(text)) {
      up.event.halt(event)
    }
  }

  up.on(element, 'click', confirmClick)
})
