import Shepherd from 'shepherd.js'
import 'shepherd.js/dist/css/shepherd.css'

let tours = {}
let storage
try {
  storage = window.localStorage
} catch (error) {
  storage = false
}

function start(name, forceStart = true) {
  endCurrentTour()

  let tour = tours[name]

  if (!tour) throw `Tour "${name}" could not be found`
  if (tourEnded(tour) && !forceStart) return

  tour.start()
}

function add(name, steps, addFinalStep = true) {
  if (tours[name]) throw `Tour "${name}" has already been defined`

  let tourDefaults = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
        label: 'Schließen',
      },
      popperOptions: {
        modifiers: [
          { name: 'offset', options: { offset: [0, 20] }},
        ],
      },
      scrollTo: { behavior: 'smooth', block: 'center' },
      showOn() {
        if (this.attachTo?.element && !document.querySelector(this.attachTo.element)) {
          // If the step is marked as orphan, it should be shown even if no element exists
          if (this.orphan) {
            delete this.attachTo.element
            return true
          }
          // Otherwise, skip this step
          return false
        }

        return true
      }
    },
    useModalOverlay: true,
    tourName: name,
  }

  if (addFinalStep) {
    steps.push({
      title: 'Vielen Dank für Ihre Aufmerksamkeit!',
      text: `
        <p>Bitte klicken Sie auf „Schließen“ um die Hilfe zu beenden.</p>
      `
    })
  }

  steps.forEach((step) => {
    step.attachTo ??= {}
    step.attachTo.on ??= 'auto'
    step.buttons = [
      {
        action() { return this.back() },
        classes: 'btn btn-outline-secondary',
        label: 'Zurück',
        text: '« Zurück',
      },
      {
        action() { return this.next() },
        classes: 'btn btn-outline-secondary',
        label: 'Weiter',
        text: 'Weiter »',
      },
    ]
  })

  steps[0].buttons[0].disabled = true
  steps[steps.length - 1].buttons[1] = {
    action() { return this.complete() },
    classes: 'btn btn-outline-secondary',
    label: 'Schließen',
    text: 'Schließen',
  }

  const tour = new Shepherd.Tour(Object.assign(tourDefaults, { tourName: name }))
  tour.addSteps(steps)
  tour.ended = false
  tour.on('hide', onHide)
  tour.on('show', onShown)
  tour.on('complete', onEnd)
  tour.on('cancel', onEnd)

  tours[name] = tour
}

function onShown({ step }) {
  step.getTarget()?.closest('.navigation')?.classList.add('-tour')
}

function onHide({ previous }) {
  previous.getTarget()?.closest('.navigation')?.classList.remove('-tour')
}

function onEnd({ tour }) {
  restoreNavigation()
  tour.ended = true

  if (storage) {
    let keyName = `${tour.options.tourName}_end`
    storage.setItem(keyName, true)
  }
}

function tourEnded(tour) {
  let storageValue = undefined
  if (storage) {
    let keyName = `${tour.options.tourName}_end`
    storageValue = storage.getItem(keyName)
  }

  return storageValue !== null ? storageValue : false
}

function openNavigationFor(element) {
  let navbar = element.closest('.navbar')

  if (!navbar.matches('.-open')) {
    let navLinkToOpen = document.querySelector(`.nav-link[up-data='"#${navbar.id}"']`)
    navLinkToOpen.click()
  }
}

function restoreNavigation() {
  document.querySelector('.navigation').click()
}

function endCurrentTour() {
  if (Shepherd.activeTour) Shepherd.activeTour.end()
}

export default {
  start: start,
  add: add,
  endCurrentTour: endCurrentTour,
  openNavigationFor: openNavigationFor,
}
